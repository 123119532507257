// App.js
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import DetailPage from './components/DetailPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ListingsProvider } from './components/ListingsContext';
import { HardwareListingsProvider } from './components/HardwareListingsContext';
import { ColocationListingsProvider } from './components/ColocationListingsContext';
import './App.css';
import backgroundImage from './components/assets/bg.jpg';
import MyBiddings from './components/MyBiddings';
import HardwareHome from './components/HardwareHome';
import GPUHome from './components/GPUHome';
import HardwareDashboard from './components/HardwareDashboard';
import HardwareDetailPage from './components/HardwareDetailPage';
import ColocationHome from './components/ColocationHome';
import ColocationDashboard from './components/ColocationDashboard';
import ColocationDetailPage from './components/ColocationDetailPage';
import SideNav from './components/SideNav';
import DashboardLayout from './components/DashboardLayout';

function TitleUpdater() {
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === '/hardware') {
      document.title = 'FPX Hardware Marketplace';
    } else if (location.pathname === '/colocation') {
      document.title = 'FPX Colocation Marketplace';
    } else if (location.pathname === '/GPU' || location.pathname === '/') {
      document.title = 'FPX GPU Marketplace';
    }
  }, [location]);
  
  return null;
}

function AppContent() {
  return (
    <ListingsProvider>
      <HardwareListingsProvider>
        <ColocationListingsProvider>
          <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <TitleUpdater />
            <Routes>
              <Route path="/" element={<GPUHome />} />          
              <Route path="/hardware" element={<HardwareHome />} />
              <Route path="/GPU" element={<GPUHome/>}/>
              <Route path="/colocation" element={<ColocationHome/>}/>
              <Route
                path="/hardwaredashboard"
                element={
                  <>
                    <Navbar />
                    <DashboardLayout>
                      <HardwareDashboard />
                    </DashboardLayout>
                    <Footer />
                  </>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <>
                    <Navbar />
                    <DashboardLayout>
                      <Dashboard />
                    </DashboardLayout>
                    <Footer />
                  </>
                }
              />
              <Route
                path="/colocationdashboard"
                element={
                  <>
                    <Navbar />
                    <DashboardLayout>
                      <ColocationDashboard />
                    </DashboardLayout>
                    <Footer />
                  </>
                }
              />
              <Route
                path="/hardware-detail/:id"
                element={
                  <>
                    <Navbar />
                    <HardwareDetailPage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/detail/:id"
                element={
                  <>
                    <Navbar />
                    <DetailPage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/colocation-detail/:id"
                element={
                  <>
                    <Navbar />
                    <ColocationDetailPage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/my-biddings"
                element={
                  <>
                    <Navbar />
                    <DashboardLayout>
                      <MyBiddings />
                    </DashboardLayout>
                    <Footer />
                  </>
                }
              />
            </Routes>
          </div>
        </ColocationListingsProvider>
      </HardwareListingsProvider>
    </ListingsProvider>
  );
}

// Main App component
function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;