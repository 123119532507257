// ColocationHome.js
import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';
import { useListings } from './ListingsContext';

const ColocationHome = () => {
  const navigate = useNavigate();
  const { setListingType } = useListings();
  
  // Set listing type when component mounts
  useEffect(() => {
    setListingType('colocation');
  }, [setListingType]);
  
  const handleGetStarted = () => {
    navigate('/colocationdashboard');
  };

  return (
    <div className="home-container">
      <nav className="custom-navbar">
        <div className="nav-brand">
          <span className="brand-name">FPX Colocation Sites Marketplace</span>
        </div>
      </nav>

      <Container>
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="marketplace-title">Colocation Sites Marketplace</h1>
            <h2 className="subtitle">Your Go To Place For Fully-Transparent<br />Data Center Space and Power</h2>
            
            <div className="button-container">
              <Button onClick={handleGetStarted} className="get-started-btn">View Listings <span className="arrow">→</span></Button>
            </div>
            
            <div className="hpc-investment-section">
              <h3 className="hpc-title">Decode Your HPC Investment</h3>
              <a href="https://fpx.world/consulting/datacenter/tco" target="_blank" rel="noopener noreferrer">
                <Button className="hpc-button">
                  <span>View FPX's TCO Model</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ColocationHome;