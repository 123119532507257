// ListingsContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import mockData from '../data/listings.json';
import hardwareData from '../data/hardware.json';

const ListingsContext = createContext();

export const useListings = () => useContext(ListingsContext);

export const ListingsProvider = ({ children }) => {
  const [allListings, setAllListings] = useState([]); 
  const [listings, setListings] = useState([]);
  const [dataSource, setDataSource] = useState('gpu'); // Default to GPU listings
  const [listingType, setListingType] = useState('gpu'); // Add this for compatibility with the approach
  
  useEffect(() => {
    // Load appropriate data based on data source
    if (dataSource === 'hardware') {
      setAllListings(hardwareData.listings);
      setListings(hardwareData.listings);
    } else {
      setAllListings(mockData.listings);
      setListings(mockData.listings);
    }
  }, [dataSource]);

  // Update dataSource when listingType changes
  useEffect(() => {
    setDataSource(listingType);
  }, [listingType]);

  const updateListings = (newListings) => {
    setListings(newListings);
  };

  const updateListing = (updatedListing) => {
    const updatedAllListings = allListings.map(listing => 
      listing.id === updatedListing.id ? updatedListing : listing
    );
    setAllListings(updatedAllListings);
    setListings(prevListings => 
      prevListings.map(listing => 
        listing.id === updatedListing.id ? updatedListing : listing
      )
    );
  };
  
  // Keep the original setListingType function but make it update both
  const setListingTypeWrapper = (type) => {
    setListingType(type);
    setDataSource(type);
  };

  return (
    <ListingsContext.Provider value={{ 
      listings, 
      allListings,
      updateListings, 
      updateListing,
      setListingType: setListingTypeWrapper, 
      dataSource,
      listingType
    }}>
      {children}
    </ListingsContext.Provider>
  );
};

export default ListingsContext;