import React from 'react';
import SideNav from './SideNav';
import styles from './styles/DashboardLayout.module.css';

const DashboardLayout = ({ children }) => {
  return (
    <div className={styles.dashboardLayout}>
      <SideNav />
      <div className={styles.mainContent}>
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;