import React from 'react';
import { useListings } from './ListingsContext';
import { useHardwareListings } from './HardwareListingsContext';
import { useColocationListings } from './ColocationListingsContext';
import { Link } from 'react-router-dom';
import styles from './styles/MyBiddings.module.css';

const MyBiddings = () => {
  const { listings: gpuListings, listingType } = useListings();
  const { hardwareListings } = useHardwareListings();
  const { colocationListings } = useColocationListings();
  
  // Use the appropriate listings based on type
  let listings = [];
  if (listingType === 'hardware') {
    listings = hardwareListings;
  } else if (listingType === 'colocation') {
    listings = colocationListings;
  } else {
    listings = gpuListings;
  }
  
  const myBids = listings.filter(listing => listing.highest_bidder_username === 'You');

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  // Determine the correct detail page URL based on listing type
  const getDetailUrl = (listingId) => {
    if (listingType === 'hardware') return `/hardware-detail/${listingId}`;
    if (listingType === 'colocation') return `/colocation-detail/${listingId}`;
    return `/detail/${listingId}`;
  };

  // Determine the correct dashboard URL based on listing type
  const getDashboardUrl = () => {
    if (listingType === 'hardware') return '/hardwaredashboard';
    if (listingType === 'colocation') return '/colocationdashboard';
    return '/dashboard';
  };

  // Determine the correct title based on listing type
  const getTitle = () => {
    if (listingType === 'hardware') return 'My Hardware Biddings';
    if (listingType === 'colocation') return 'My Colocation Biddings';
    return 'My GPU Biddings';
  };

  // Determine the name field to display based on listing type
  const getDisplayName = (listing) => {
    if (listingType === 'hardware') return (listing.hardware_type || listing.model);
    if (listingType === 'colocation') return listing.sitename;
    return listing.gpu_type;
  };

  const getQuantityUnit = (listing) => {
    if (!listing || !listing.category) return '';
    
    if (listingType === 'colocation' && listing.power_required) {
      return 'kW';
    }
    
    const category = listing.category?.toLowerCase();
    if (category === 'fiber optic networking') return 'meter';
    
    if ((category === 'cooling' || category === 'cooling systems')) {
      return 'plates';
    }
    
    return '';
  };

  // Calculate total bid amount (bid × quantity)
  const calculateTotalBid = (listing) => {
    if (listingType === 'colocation' && listing.power_required) {
      return listing.current_highest_bid * listing.power_required;
    }
    
    if (!listing.current_highest_bid || !listing.quantity) return listing.current_highest_bid;
    return listing.current_highest_bid * listing.quantity;
  };

  // Get location display based on listing type
  const getLocationDisplay = (listing) => {
    if (listingType === 'colocation') {
      return listing.location || 'Location not specified';
    }
    
    return listing.state && listing.country 
      ? `${listing.state}, ${listing.country}`
      : 'Location not specified';
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{getTitle()}</h1>
      
      {myBids.length === 0 ? (
        <div className={styles.emptyState}>
          <p>You haven't placed any bids yet.</p>
          <Link to={getDashboardUrl()} className={styles.browseButton}>
            Browse Listings
          </Link>
        </div>
      ) : (
        <div className={styles.biddingsGrid}>
          {myBids.map((listing) => (
            <div key={listing.id} className={styles.biddingCard}>
              <div className={styles.cardHeader}>
                <h3 className={styles.gpuType}>{getDisplayName(listing)}</h3>
                {listing.server_name && <span className={styles.serverName}>{listing.server_name}</span>}
                {listing.manufacturer && <span className={styles.serverName}>{listing.manufacturer}</span>}
                {listingType === 'colocation' && listing.type && (
                  <span className={styles.serverName}>{listing.type}</span>
                )}
              </div>
              
              <div className={styles.bidInfo}>
                <div className={styles.priceRow}>
                  <span className={styles.label}>Ask Price:</span>
                  <span className={styles.value}>
                    ${formatCurrency(listing.ask_price)}
                    {listingType === 'colocation' ? ' per kW/month' : getQuantityUnit(listing) && ` per ${getQuantityUnit(listing)}`}
                  </span>
                </div>
                <div className={styles.priceRow}>
                  <span className={styles.label}>Your Bid:</span>
                  <span className={styles.value}>
                    ${formatCurrency(listing.current_highest_bid)}
                    {listingType === 'colocation' ? ' per kW/month' : getQuantityUnit(listing) && ` per ${getQuantityUnit(listing)}`}
                  </span>
                </div>
                
                {listingType === 'colocation' && listing.power_required && (
                  <>
                    <div className={styles.priceRow}>
                      <span className={styles.label}>Power Required:</span>
                      <span className={styles.value}>
                        {listing.power_required} kW
                      </span>
                    </div>
                    <div className={styles.priceRow}>
                      <span className={styles.label}>Total Monthly Cost:</span>
                      <span className={styles.value}>
                        ${formatCurrency(calculateTotalBid(listing))}
                      </span>
                    </div>
                  </>
                )}
                
                {listingType !== 'colocation' && listing.quantity && getQuantityUnit(listing) && (
                  <>
                    <div className={styles.priceRow}>
                      <span className={styles.label}>Total Length:</span>
                      <span className={styles.value}>
                        {listing.quantity.toLocaleString()} {getQuantityUnit(listing)}
                      </span>
                    </div>
                    <div className={styles.priceRow}>
                      <span className={styles.label}>Total Price:</span>
                      <span className={styles.value}>
                        ${formatCurrency(calculateTotalBid(listing))}
                      </span>
                    </div>
                  </>
                )}
                
                <div className={styles.locationRow}>
                  <span className={styles.label}>Location:</span>
                  <span className={styles.value}>{getLocationDisplay(listing)}</span>
                </div>
              </div>

              <div className={styles.cardFooter}>
                <Link 
                  to={getDetailUrl(listing.id)} 
                  className={styles.viewDetailsButton}
                >
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyBiddings;