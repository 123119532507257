// Navbar.js - Updated with hardware redirects
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useListings } from './ListingsContext';
import styles from './styles/Navbar.module.css';

const NavigationBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { allListings, updateListings, listingType } = useListings();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    if (!newSearchTerm.trim()) {
      updateListings(allListings);
      return;
    }

    const filteredListings = allListings.filter(listing => 
      (listing.gpu_type && listing.gpu_type.toLowerCase().includes(newSearchTerm.toLowerCase())) ||
      (listing.server_name && listing.server_name.toLowerCase().includes(newSearchTerm.toLowerCase())) ||
      (listing.hardware_type && listing.hardware_type.toLowerCase().includes(newSearchTerm.toLowerCase())) ||
      (listing.state && listing.state.toLowerCase().includes(newSearchTerm.toLowerCase())) ||
      (listing.country && listing.country.toLowerCase().includes(newSearchTerm.toLowerCase()))
    );
    updateListings(filteredListings);
  };

  // Handle navigation based on current listing type
  const handleListingsClick = (e) => {
    e.preventDefault();
    
    // Check if we're in the hardware section from the listingType context
    if (listingType === 'hardware') {
      navigate('/hardwaredashboard');
    } else if (listingType === 'colocation') {
      navigate('/colocationdashboard');
    } else {
    navigate('/dashboard');
    }  };

  // Handle home navigation based on listing type
  const handleHomeClick = (e) => {
    e.preventDefault();
    // Force navigation to external URL
    window.open('https://fp8.io/', '_self');
  };

  // Handle brand click navigation based on listing type
  const handleBrandClick = (e) => {
    e.preventDefault();
    // Force navigation to external URL
    window.open('https://fp8.io/hardware-marketplace', '_self');
  };

  return (
    <div className={styles.navWrapper}>
      <div className={styles.navbar}>
        <div className={styles.leftSection}>
          {/* Updated to use onClick handler */}
          <a href="#" onClick={handleBrandClick} className={styles.brand}>
            HPC-Marketplace
          </a>
          <nav className={styles.nav}>
            {/* Updated to use React Router navigation */}
            <a 
              href="#" 
              onClick={handleHomeClick} 
              className={styles.navLink}
            >
              Home
            </a>
            <a href="#" onClick={handleListingsClick} className={styles.navLink}>Listings</a>
            <Link to="/my-biddings" className={styles.navLink}>My Biddings</Link>
          </nav>
        </div>
        <div className={styles.searchSection}>
          <form onSubmit={(e) => e.preventDefault()} className={styles.searchForm}>
            <input
              type="search"
              placeholder="Search listings..."
              className={styles.searchInput}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className={styles.buttonGroup}>
              <button type="submit" className={styles.searchButton}>
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;