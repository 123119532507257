// HardwareDetailPage.js - Updated to use HardwareBidModal
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useHardwareListings } from './HardwareListingsContext';
import HardwareBidModal from './HardwareBidModal';
import styles from './styles/HardwareDetail.module.css'; 

const HardwareDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { allListings } = useHardwareListings();
  const [showBidModal, setShowBidModal] = useState(false);
  
  const listing = allListings.find(item => item.id === parseInt(id) || item.id === id);
  
  const handleBidClick = () => {
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
  };
  
  const handleBackClick = () => {
    navigate('/hardwaredashboard');
  };
  
  if (!listing) {
    return (
      <Container className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button onClick={handleBackClick} className={styles.backButton}>
            Back to Listings
          </Button>
        </div>
      </Container>
    );
  }

  const renderSpecification = (spec, value) => (
    <div className={styles.specRow} key={spec}>
      <span className={styles.specLabel}>{spec}</span>
      <span className={styles.specValue}>{value || 'Not specified'}</span>
    </div>
  );

  return (
    <Container className={styles.container}>
      <div style={{ marginBottom: '20px' }}>
        <Button onClick={handleBackClick} className={styles.backButton}>
          Back to Listings
        </Button>
      </div>
      
      <div className={styles.detailCard}>
        <h1 className={styles.title}>{listing.hardware_type || listing.model}</h1>
        
        <div className={styles.priceSection}>
          <div className={styles.askPrice}>
            Ask Price: ${parseFloat(listing.ask_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </div>
          {listing.current_highest_bid && (
            <div className={styles.currentBid}>
              Current Highest Bid: ${parseFloat(listing.current_highest_bid).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </div>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
          <Button onClick={handleBidClick} className={styles.bidButton}>
            Place Bid
          </Button>
        </div>
        
        <div className={styles.specSection}>
          <div className={styles.specsList}>
            {renderSpecification('Manufacturer', listing.manufacturer)}
            {renderSpecification('Model', listing.model)}
            {renderSpecification('Year', listing.year)}
            {renderSpecification('Condition', listing.condition)}
            {listing.specifications && Object.entries(listing.specifications).map(([key, value]) => 
              renderSpecification(key, typeof value === 'object' ? JSON.stringify(value) : value)
            )}
          </div>
        </div>
        
        <div className={styles.locationSection}>
          <div className={styles.specsList}>
            {renderSpecification('Country', listing.country)}
            {renderSpecification('State/Region', listing.state)}
            {renderSpecification('City', listing.city)}
          </div>
        </div>
      </div>

      <HardwareBidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={listing}
      />
    </Container>
  );
};

export default HardwareDetailPage;