import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useHardwareListings } from './HardwareListingsContext';
import styles from './styles/BidModal.module.css';

const HardwareBidModal = ({ show, handleClose, listing }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [minBidAmount, setMinBidAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [quantity, setQuantity] = useState('');
  const { updateListing } = useHardwareListings();

  useEffect(() => {
    if (listing) {
      const minBid = listing.current_highest_bid 
        ? listing.current_highest_bid + 1
        : Math.round(listing.ask_price * 0.4);
      setMinBidAmount(minBid);
      setBidAmount('');
      setIsChecked(false);
      
      // Set default quantities based on category
      if (listing.category?.toLowerCase() === 'fiber optic networking') {
        setQuantity('10000');
      } else if (listing.category?.toLowerCase() === 'cooling' || 
                listing.category?.toLowerCase() === 'cooling systems') {
        setQuantity('128');
      } else {
        setQuantity('');
      }
    }
    setMessage({ type: '', content: '' });
  }, [listing, show]);

  const calculateTotalBid = () => {
    if (!bidAmount || !listing || !isNeedingQuantity()) return bidAmount;
    return parseFloat(bidAmount) * (quantity || 0);
  };

  const isNeedingQuantity = () => {
    if (!listing || !listing.category) return false;
    
    const category = listing.category.toLowerCase();
    if (category === 'fiber optic networking') return true;
    
    if (category === 'cooling' || category === 'cooling systems') {
      return true;
    }
    
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });

    if (!listing) {
      setMessage({ type: 'danger', content: 'No hardware listing selected' });
      return;
    }

    if (!isChecked) {
      setMessage({ type: 'danger', content: 'Please confirm the terms and conditions' });
      return;
    }

    const bidAmountFloat = parseFloat(bidAmount);
    
    // Validate bid amount
    if (listing.current_highest_bid && bidAmountFloat <= listing.current_highest_bid) {
      setMessage({ 
        type: 'danger', 
        content: `Bid must be higher than the current highest bid ($${Number(listing.current_highest_bid).toLocaleString()})` 
      });
      return;
    }
    
    if (bidAmountFloat < minBidAmount) {
      setMessage({ 
        type: 'danger', 
        content: `Bid must be at least $${minBidAmount.toLocaleString()}` 
      });
      return;
    }

    // Validate quantity for applicable categories
    const qtyValue = parseInt(quantity);
    if ((needsQuantity(listing) && (!qtyValue || qtyValue <= 0))) {
      setMessage({ 
        type: 'danger', 
        content: `Please enter a valid quantity` 
      });
      return;
    }

    // Update the listing with the new bid
    const updatedListing = {
      ...listing,
      current_highest_bid: bidAmountFloat,
      highest_bidder_username: 'You', // Mark as user's bid
      quantity: needsQuantity(listing) ? qtyValue : undefined
    };

    updateListing(updatedListing);
    setMessage({ type: 'success', content: 'Bid placed successfully!' });

    // Close modal after success message
    setTimeout(() => {
      handleClose();
      setMessage({ type: '', content: '' });
      setIsChecked(false);
    }, 2000);
  };

  // Helper function to determine if quantity is needed
  const needsQuantity = (listing) => {
    if (!listing || !listing.category) return false;
    
    const category = listing.category.toLowerCase();
    if (category === 'fiber optic networking') return true;
    
    if (category === 'cooling' || category === 'cooling systems') {
      return true;
    }
    
    return false;
  };

  // Helper to determine the quantity unit
  const getQuantityUnit = (listing) => {
    if (!listing || !listing.category) return '';
    
    const category = listing.category.toLowerCase();
    if (category === 'fiber optic networking') return 'meters';
    
    if (category === 'cooling' || category === 'cooling systems') {
      return 'plates';
    }
    
    return '';
  };

  if (!listing) {
    return null;
  }

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toLocaleString('en-US');
  };

  return (
    <Modal show={show} onHide={handleClose} className={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Place a Bid on {listing.hardware_type}</Modal.Title>
        <button className={styles.closeButton} onClick={handleClose}>×</button>
      </Modal.Header>
      
      <Modal.Body className={styles.modalBody}>
        <p className={styles.modalSubtitle}>
          You will obtain this hardware if your bid is the highest when the auction ends
        </p>
        <Form onSubmit={handleSubmit}>
          <div className={styles.infoRow}>
            <div className={styles.infoText}>
              Ask Price: ${formatCurrency(listing?.ask_price)}
              {needsQuantity(listing) && ` per ${getQuantityUnit(listing)}`}
            </div>
            {listing.current_highest_bid ? (
              <div className={styles.currentBid}>
                Current Highest Bid: ${formatCurrency(listing.current_highest_bid)}
              </div>
            ) : (
              <div className={styles.infoText}>
                Minimum Bid: ${formatCurrency(minBidAmount)}
              </div>
            )}
          </div>

          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Your Bid Amount (USD)
              {needsQuantity(listing) && ` per ${getQuantityUnit(listing)}`}
            </Form.Label>
            <Form.Control
              className={styles.formControl}
              type="number"
              step="1"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
              required
              min={minBidAmount}
            />
          </Form.Group>
          {isNeedingQuantity() && bidAmount && quantity && (
            <div className={styles.totalBidAmount}>
                <strong>Total Bid Amount:</strong> ${(parseFloat(bidAmount) * parseInt(quantity)).toLocaleString()}
            </div>
            )}
          {needsQuantity(listing) && (
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>
                Quantity ({getQuantityUnit(listing)})
              </Form.Label>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="1"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
                min="1"
              />
            </Form.Group>
          )}

          <Form.Group className={styles.checkboxGroup}>
            <Form.Check
              type="checkbox"
              id="terms-checkbox"
              className={styles.checkbox}
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              label="I confirm that I understand this bid is binding and subject to the marketplace terms"
            />
          </Form.Group>

          {message.content && (
            <Alert className={`${styles.alert} ${styles[message.type]}`}>
              {message.content}
            </Alert>
          )}

          <Button className={styles.submitButton} type="submit">
            Place Bid
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default HardwareBidModal;