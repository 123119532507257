// ColocationDetailPage.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useColocationListings } from './ColocationListingsContext';
import ColocationBidModal from './ColocationBidModal';
import styles from './styles/HardwareDetail.module.css'; 

const ColocationDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { allListings } = useColocationListings();
  const [showBidModal, setShowBidModal] = useState(false);
  
  const listing = allListings.find(item => item.id === parseInt(id) || item.id === id);
  
  const handleBidClick = () => {
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
  };
  
  const handleBackClick = () => {
    navigate('/colocationdashboard');
  };
  
  if (!listing) {
    return (
      <Container className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button onClick={handleBackClick} className={styles.backButton}>
            Back to Listings
          </Button>
        </div>
      </Container>
    );
  }

  const renderSpecification = (spec, value) => (
    <div className={styles.specRow} key={spec}>
      <span className={styles.specLabel}>{spec}</span>
      <span className={styles.specValue}>{value || 'Not specified'}</span>
    </div>
  );

  return (
    <Container className={styles.container}>
      <div style={{ marginBottom: '20px' }}>
        <Button onClick={handleBackClick} className={styles.backButton}>
          Back to Listings
        </Button>
      </div>
      
      <div className={styles.detailCard}>
        <h1 className={styles.title}>{listing.sitename}</h1>
        
        <div className={styles.priceSection}>
          <div className={styles.askPrice}>
            Ask Price: ${parseFloat(listing.ask_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} per kW/month
          </div>
          {listing.current_highest_bid && (
            <div className={styles.currentBid}>
              Current Highest Bid: ${parseFloat(listing.current_highest_bid).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} per kW/month
            </div>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
          <Button onClick={handleBidClick} className={styles.bidButton}>
            Place Bid
          </Button>
        </div>
        
        <div className={styles.specSection}>
          <h3 className={styles.sectionTitle}>Datacenter Specifications</h3>
          <div className={styles.specsList}>
            {renderSpecification('Location', listing.location)}
            {renderSpecification('Type', listing.type)}
            {renderSpecification('Total Power', listing.totalPower)}
            {renderSpecification('Expansion Timeline', listing.expansionTimeline)}
            {renderSpecification('Tier Rating', listing.tierRating)}
            {renderSpecification('Carrier Neutrality', listing.carrierNeutrality)}
            {renderSpecification('Cross-Connect Availability', listing.crossConnectAvailability)}
          </div>
        </div>
        
        <div className={styles.specSection}>
          <h3 className={styles.sectionTitle}>Connectivity Details</h3>
          <div className={styles.specsList}>
            {renderSpecification('Fiber', listing.connectivityDetails.fiber)}
            {renderSpecification('IXP Access', listing.connectivityDetails.ixpAccess)}
          </div>
        </div>
        
        <div className={styles.specSection}>
          <h3 className={styles.sectionTitle}>Lease Terms</h3>
          <div className={styles.specsList}>
            {renderSpecification('Scalability', listing.scalability)}
          </div>
        </div>
      </div>

      <ColocationBidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={listing}
      />
    </Container>
  );
};

export default ColocationDetailPage;