// SideNav.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  FaDatabase, 
  FaHdd, 
  FaMicrochip, 
  FaRobot, 
  FaChevronLeft, 
  FaChevronRight, 
  FaDesktop,
  FaMemory,
  FaNetworkWired
} from 'react-icons/fa';
import styles from './styles/SideNav.module.css';

const SideNav = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [hardwareExpanded, setHardwareExpanded] = useState(true);
  
  // Automatically collapse hardware submenu when sidebar is collapsed
  useEffect(() => {
    if (collapsed) {
      setHardwareExpanded(false);
    }
  }, [collapsed]);

  // Determine which nav item is active
  const isActive = (path) => {
    if (path === '/dashboard' && (location.pathname === '/dashboard' || location.pathname === '/')) 
      return true;
    return location.pathname === path;
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleHardwareExpand = (e) => {
    e.stopPropagation();
    if (!collapsed) {
      setHardwareExpanded(!hardwareExpanded);
    }
  };

  const largeIconStyle = {
    fontSize: '24px',
    marginRight: '10px'
  };

  return (
    <div className={`${styles.sideNav} ${collapsed ? styles.sideNavCollapsed : ''}`}>
      <div className={styles.navHeader}>
        {!collapsed && <span>Marketplace Listings</span>}
        <div className={`${styles.toggleButton} ${collapsed ? styles.toggleButtonCollapsed : ''}`} onClick={toggleCollapse}>
          <span className={styles.toggleIcon}>
            {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
          </span>
        </div>
      </div>
      <ul className={styles.navItems}>
        {/* External links */}
        <li className={`${styles.navItem}`}>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              window.open('https://compute.fp8.io/gpu-dashboard', '_self');
            }}
          >
            {collapsed ? (
              <FaNetworkWired style={largeIconStyle} />
            ) : (
              <>
                <FaNetworkWired style={largeIconStyle} />
                <span className={styles.navText}>GPU Marketplace</span>
              </>
            )}
          </a>
        </li>
        <li className={`${styles.navItem}`}>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              window.open('https://dashboard.fp8.io/', '_self');
            }}
          >
            {collapsed ? (
              <FaRobot style={largeIconStyle} />
            ) : (
              <>
                <FaRobot style={largeIconStyle} />
                <span className={styles.navText}>AI Marketplace</span>
              </>
            )}
          </a>
        </li>
        
        {/* Hardware Marketplace parent item */}
        <li className={`${styles.navItem} ${styles.parentItem}`} onClick={toggleHardwareExpand}>
          {collapsed ? (
            <FaDesktop style={largeIconStyle} />
          ) : (
            <>
              <FaDesktop style={largeIconStyle} />
              <span className={styles.navText}>HPC Equipment Marketplace</span>
              <span className={`${styles.expandIcon} ${hardwareExpanded ? styles.expanded : ''}`}>
                {hardwareExpanded ? '▼' : '►'}
              </span>
            </>
          )}
        </li>

        {/* Sub-navigation items under Hardware Marketplace */}
        {(hardwareExpanded && !collapsed) && (
          <>
            <li className={`${styles.navItem} ${styles.subItem} ${isActive('/dashboard') ? styles.active : ''}`}>
              <Link to="/dashboard">
                {collapsed ? (
                  <FaMemory style={largeIconStyle} />
                ) : (
                  <>
                    <FaMemory style={largeIconStyle} />
                    <span className={styles.navText}>GPU Hardware for sale</span>
                  </>
                )}
              </Link>
            </li>
            <li className={`${styles.navItem} ${styles.subItem} ${isActive('/colocationdashboard') ? styles.active : ''}`}>
              <Link to="/colocationdashboard">
                {collapsed ? (
                  <FaDatabase style={largeIconStyle} />
                ) : (
                  <>
                    <FaDatabase style={largeIconStyle} />
                    <span className={styles.navText}>Colocation sites for sale</span>
                  </>
                )}
              </Link>
            </li>
            <li className={`${styles.navItem} ${styles.subItem} ${isActive('/hardwaredashboard') ? styles.active : ''}`}>
              <Link to="/hardwaredashboard">
                {collapsed ? (
                  <FaHdd style={largeIconStyle} />
                ) : (
                  <>
                    <FaHdd style={largeIconStyle} />
                    <span className={styles.navText}>HPC Equipment for sale</span>
                  </>
                )}
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SideNav;