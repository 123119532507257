import React, { useState, useEffect, useRef } from 'react';
import ColocationListingCard from './ColocationListingCard';
import ColocationBidModal from './ColocationBidModal';
import { useColocationListings } from './ColocationListingsContext';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';

const ColocationDashboard = () => {
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedType, setSelectedType] = useState('all');
  const [selectedTier, setSelectedTier] = useState('all');
  const [selectedPower, setSelectedPower] = useState('all');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRefs = {
    type: useRef(null),
    tier: useRef(null),
    power: useRef(null)
  };
  
  const colocationListings = useColocationListings();
  const { setListingType } = useListings();
  
  // Set listing type when component mounts
  useEffect(() => {
    setListingType('colocation');
  }, [setListingType]);

  // Click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && 
          dropdownRefs[activeDropdown].current && 
          !dropdownRefs[activeDropdown].current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);
  
  // Check if the hook returns expected values
  if (!colocationListings) {
    return <div className={styles.container}>Error loading colocation listings context</div>;
  }
  
  const { colocationListings: listings = [], loading = false } = colocationListings;

  // Create filters based on the data
  const types = [
    { id: 'all', name: 'All Types' },
    { id: 'Greenfield', name: 'Greenfield' },
    { id: 'Brownfield', name: 'Brownfield' }
  ];

  const tiers = [
    { id: 'all', name: 'All Tiers' },
    { id: 'Tier III', name: 'Tier III' },
    { id: 'Tier IV', name: 'Tier IV' }
  ];

  const powerRanges = [
    { id: 'all', name: 'All Power Capacities' },
    { id: 'under50', name: 'Under 50 MW' },
    { id: '50to80', name: '50 - 80 MW' },
    { id: 'over80', name: 'Over 80 MW' }
  ];

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  const handleFilterSelect = (filterType, value) => {
    switch(filterType) {
      case 'type':
        setSelectedType(value);
        break;
      case 'tier':
        setSelectedTier(value);
        break;
      case 'power':
        setSelectedPower(value);
        break;
      default:
        break;
    }
    setActiveDropdown(null);
  };

  // Get the currently selected filter names
  const selectedTypeName = types.find(type => type.id === selectedType)?.name || 'All Types';
  const selectedTierName = tiers.find(tier => tier.id === selectedTier)?.name || 'All Tiers';
  const selectedPowerName = powerRanges.find(power => power.id === selectedPower)?.name || 'All Power Capacities';

  // Filter listings by selected criteria
  const filterByPower = (listing) => {
    if (selectedPower === 'all') return true;
    
    const powerString = listing.totalPower;
    if (!powerString) return false;
    
    const powerValue = parseInt(powerString.split(' ')[0]);
    if (isNaN(powerValue)) return false;
    
    switch(selectedPower) {
      case 'under50':
        return powerValue < 50;
      case '50to80':
        return powerValue >= 50 && powerValue <= 80;
      case 'over80':
        return powerValue > 80;
      default:
        return true;
    }
  };

  const filteredListings = listings.filter(listing => {
    const typeMatch = selectedType === 'all' || listing.type === selectedType;
    const tierMatch = selectedTier === 'all' || listing.tierRating === selectedTier;
    const powerMatch = filterByPower(listing);
    
    return typeMatch && tierMatch && powerMatch;
  });

  if (loading) {
    return <div className={styles.container}>Loading colocation listings...</div>;
  }

  // Function to render dropdown
  const renderDropdown = (type, options, selectedValue, selectedName) => {
    return (
      <div className={styles.dropdownContainer} ref={dropdownRefs[type]}>
        <button 
          className={styles.dropdownToggle} 
          onClick={() => toggleDropdown(type)}
        >
          <span>{selectedName}</span>
          <svg className={`${styles.chevron} ${activeDropdown === type ? styles.open : ''}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        
        {activeDropdown === type && (
          <div className={styles.dropdownMenu}>
            {options.map(option => (
              <div 
                key={option.id} 
                className={`${styles.dropdownItem} ${selectedValue === option.id ? styles.active : ''}`}
                onClick={() => handleFilterSelect(type, option.id)}
              >
                {selectedValue === option.id && (
                  <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                )}
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Colocation Data Centers</h1>
      
      <div className={styles.filterContainer}>
        <div className={styles.filtersRow}>
          {renderDropdown('type', types, selectedType, selectedTypeName)}
          {renderDropdown('tier', tiers, selectedTier, selectedTierName)}
          {renderDropdown('power', powerRanges, selectedPower, selectedPowerName)}
        </div>
      </div>

      <div className={styles.listingsWrapper}>
        {filteredListings.length === 0 ? (
          <div className="text-center mt-4">
            <h3>No colocation listings available matching these filters</h3>
          </div>
        ) : (
          <div className={styles.listingsGrid}>
            {filteredListings.map((listing) => (
              <div className={styles.cardWrapper} key={listing.id}>
                <ColocationListingCard 
                  listing={listing}
                  onBidClick={handleBidClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <ColocationBidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={selectedListing}
      />
    </div>
  );
};

export default ColocationDashboard;