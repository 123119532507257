// HardwareHome.js - Modification to set listingType
import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';
import { useListings } from './ListingsContext';

const HardwareHome = () => {
  const navigate = useNavigate();
  const { setListingType } = useListings();
  
  // Set listing type when component mounts
  useEffect(() => {
    setListingType('hardware');
  }, [setListingType]);
  
  const handleGetStarted = () => {
    navigate('/hardwaredashboard');
  };

  return (
    <div className="home-container">
      <nav className="custom-navbar">
        <div className="nav-brand">
          <span className="brand-name">FPX Datacenter Equipment Marketplace</span>
        </div>
      </nav>

      <Container>
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="marketplace-title">Datacenter Equipment Marketplace</h1>
            <h2 className="subtitle">Your Go To Place For Fully-Transparent<br />HPC Brokerage Services</h2>
            
            <div className="button-container">
              <Button onClick={handleGetStarted} className="get-started-btn">View Listings <span className="arrow">→</span></Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HardwareHome;