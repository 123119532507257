import React, { createContext, useContext, useState, useEffect } from 'react';
import colocationData from '../data/colocation.json';

const ColocationListingsContext = createContext();

export const useColocationListings = () => useContext(ColocationListingsContext);

export const ColocationListingsProvider = ({ children }) => {
  const [colocationListings, setColocationListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    // Generate a random bid that's a certain percentage of the ask price
    const generateRandomBid = (askPrice) => {
      // Random percentage between 75% and 95% of ask price
      const percentage = Math.random() * 0.2 + 0.75;
      return Math.round(askPrice * percentage);
    };

    // Format listings with random bids for all items
    const formattedListings = colocationData.listings.map(item => {
      // Convert price string to number (from format like "$270 per kW/month")
      const priceString = item.Lease_Terms?.Price || item["Lease Terms"]?.Price || "0";
      const askPrice = parseFloat(priceString.replace(/[^0-9.]/g, ''));
      
      // Generate random bid amount
      const randomBid = generateRandomBid(askPrice);
      
      // Generate random username for highest bidder
      const randomName = `User${String.fromCharCode(65 + Math.floor(Math.random() * 26))}`;
      
      // Format connectivity details
      const connectivityDetails = {
        fiber: item.Connectivity_Details?.Fiber || item["Connectivity Details"]?.Fiber || "Not specified",
        ixpAccess: item.Connectivity_Details?.["IXP Access"] || item["Connectivity Details"]?.["IXP Access"] || "Not specified"
      };
      
      return {
        id: item.id,
        sitename: item.sitename,
        location: item.location,
        type: item.type,
        totalPower: item["Total Power"],
        expansionTimeline: item["Expansion Timeline"],
        tierRating: item["Tier Rating"],
        carrierNeutrality: item["Carrier Neutrality"],
        crossConnectAvailability: item["Cross-Connect Availability"],
        connectivityDetails: connectivityDetails,
        ask_price: askPrice,
        current_highest_bid: randomBid,
        highest_bidder_username: randomName,
        specifications: {
          totalPower: item["Total Power"],
          expansionTimeline: item["Expansion Timeline"],
          tierRating: item["Tier Rating"]
        },
        seller_name: "FPX Colocation",
        seller_rating: 4.9,
        scalability: item["Lease Terms"]?.Scalability || "Not specified"
      };
    });

    setColocationListings(formattedListings);
    setLoading(false);
  }, []);

  const updateListing = (updatedListing) => {
    setColocationListings(prev => 
      prev.map(listing => 
        listing.id === updatedListing.id ? updatedListing : listing
      )
    );
  };

  const value = {
    colocationListings,
    loading,
    selectedListing,
    setSelectedListing,
    updateListing,
    allListings: colocationListings
  };

  return (
    <ColocationListingsContext.Provider value={value}>
      {children}
    </ColocationListingsContext.Provider>
  );
};

export default ColocationListingsContext;