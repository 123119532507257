import React, { useState, useEffect } from 'react';
import ListingCard from './ListingCard';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';

const Dashboard = () => {
  const { listings, allListings, updateListings, setListingType } = useListings();
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  
  // Set listing type when component mounts
  useEffect(() => {
    setListingType('gpu');
  }, []); // Empty dependency array ensures this runs only once
  
  // Filter states
  const [priceRange, setPriceRange] = useState({ min: 0, max: 300000 });
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedGpuTypes, setSelectedGpuTypes] = useState([]);
  
  // Dropdown states
  const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
  const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
  const [isGpuTypeDropdownOpen, setIsGpuTypeDropdownOpen] = useState(false);

  // Get unique GPU types from listings
  const uniqueGpuTypes = [...new Set(allListings.map(listing => listing.gpu_type))];
  
  // Simplified condition options
  const conditionOptions = ["New", "Used/Refurbished"];

  // Map original conditions to simplified options
  const mapConditionToSimplified = (condition) => {
    return condition === "New" ? "New" : "Used/Refurbished";
  };

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  // Price range handlers
  const handlePriceSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setPriceRange(prev => ({ ...prev, max: value }));
  };

  // Condition filter handlers
  const toggleCondition = (condition) => {
    setSelectedConditions(prev => 
      prev.includes(condition)
        ? prev.filter(c => c !== condition)
        : [...prev, condition]
    );
    setIsConditionDropdownOpen(false); // Close dropdown after selection
  };

  // GPU type filter handlers
  const toggleGpuType = (gpuType) => {
    setSelectedGpuTypes(prev => 
      prev.includes(gpuType)
        ? prev.filter(t => t !== gpuType)
        : [...prev, gpuType]
    );
    setIsGpuTypeDropdownOpen(false); // Close dropdown after selection
  };

  // Apply filters - FIXED: removed updateListings from dependency array
  useEffect(() => {
    let filteredListings = [...allListings];
    
    // Apply price filter
    filteredListings = filteredListings.filter(listing => 
      listing.ask_price >= priceRange.min && 
      listing.ask_price <= priceRange.max
    );
    
    // Apply condition filter with simplified mapping
    if (selectedConditions.length > 0) {
      filteredListings = filteredListings.filter(listing => {
        const simplifiedCondition = mapConditionToSimplified(listing.condition);
        return selectedConditions.includes(simplifiedCondition);
      });
    }
    
    // Apply GPU type filter
    if (selectedGpuTypes.length > 0) {
      filteredListings = filteredListings.filter(listing => 
        selectedGpuTypes.includes(listing.gpu_type)
      );
    }
    
    updateListings(filteredListings);
  }, [priceRange, selectedConditions, selectedGpuTypes, allListings]); // Removed updateListings from dependencies

  // Clear all filters
  const clearFilters = () => {
    setPriceRange({ min: 0, max: 300000 });
    setSelectedConditions([]);
    setSelectedGpuTypes([]);
  };

  // Find the maximum price in listings for the slider
  const maxListingPrice = Math.max(...allListings.map(listing => listing.ask_price), 300000);

  return (
    <div className={styles.container}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className={styles.title}>GPU Listings</h1>
      </div>

      <div className={styles.filterContainer}>
        <div className={styles.filtersRow}>
          {/* Price Range Slider */}
          <div className={styles.dropdownContainer}>
            <button 
              className={styles.dropdownToggle} 
              onClick={() => setIsPriceDropdownOpen(!isPriceDropdownOpen)}
            >
              <span>Price: Up to ${priceRange.max.toLocaleString()}</span>
              <span className={`${styles.chevron} ${isPriceDropdownOpen ? styles.open : ''}`}>▼</span>
            </button>
            
            {isPriceDropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div style={{ padding: '1rem' }}>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <label htmlFor="priceSlider" style={{ color: 'white', display: 'block', marginBottom: '0.25rem' }}>
                      Max Price: ${priceRange.max.toLocaleString()}
                    </label>
                    <input
                      id="priceSlider"
                      type="range"
                      min="0"
                      max={maxListingPrice}
                      step="1000"
                      value={priceRange.max}
                      onChange={handlePriceSliderChange}
                      style={{
                        width: '100%',
                        backgroundColor: '#2d3748',
                        color: 'white',
                      }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                      <span>$0</span>
                      <span>${maxListingPrice.toLocaleString()}</span>
                    </div>
                  </div>
                  <button
                    onClick={() => setIsPriceDropdownOpen(false)}
                    style={{
                      width: '100%',
                      padding: '0.5rem',
                      backgroundColor: '#3B82F6',
                      color: 'white',
                      border: 'none',
                      borderRadius: '0.25rem',
                      cursor: 'pointer',
                      marginTop: '1rem'
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Condition Filter with New and Used/Refurbished options */}
          <div className={styles.dropdownContainer}>
            <button 
              className={styles.dropdownToggle} 
              onClick={() => setIsConditionDropdownOpen(!isConditionDropdownOpen)}
            >
              <span>
                {selectedConditions.length > 0 
                  ? `Condition: ${selectedConditions.join(', ')}` 
                  : 'Condition: All'}
              </span>
              <span className={`${styles.chevron} ${isConditionDropdownOpen ? styles.open : ''}`}>▼</span>
            </button>
            
            {isConditionDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {conditionOptions.map((condition) => (
                  <div 
                    key={condition} 
                    className={`${styles.dropdownItem} ${selectedConditions.includes(condition) ? styles.active : ''}`}
                    onClick={() => toggleCondition(condition)}
                  >
                    {selectedConditions.includes(condition) && (
                      <span className={styles.checkmark}>✓</span>
                    )}
                    {condition}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* GPU Type Filter */}
          <div className={styles.dropdownContainer}>
            <button 
              className={styles.dropdownToggle} 
              onClick={() => setIsGpuTypeDropdownOpen(!isGpuTypeDropdownOpen)}
            >
              <span>
                {selectedGpuTypes.length > 0 
                  ? `GPU Type: ${selectedGpuTypes.length > 1 ? `${selectedGpuTypes.length} selected` : selectedGpuTypes[0]}` 
                  : 'GPU Type: All'}
              </span>
              <span className={`${styles.chevron} ${isGpuTypeDropdownOpen ? styles.open : ''}`}>▼</span>
            </button>
            
            {isGpuTypeDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {uniqueGpuTypes.map((gpuType) => (
                  <div 
                    key={gpuType} 
                    className={`${styles.dropdownItem} ${selectedGpuTypes.includes(gpuType) ? styles.active : ''}`}
                    onClick={() => toggleGpuType(gpuType)}
                  >
                    {selectedGpuTypes.includes(gpuType) && (
                      <span className={styles.checkmark}>✓</span>
                    )}
                    {gpuType}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Clear Filters Button */}
          {(selectedConditions.length > 0 || selectedGpuTypes.length > 0 || 
            priceRange.min > 0 || priceRange.max < 300000) && (
            <button 
              onClick={clearFilters}
              style={{
                backgroundColor: '#6B7280',
                color: 'white',
                border: 'none',
                borderRadius: '0.375rem',
                padding: '0.75rem 1rem',
                cursor: 'pointer'
              }}
            >
              Clear Filters
            </button>
          )}
        </div>

        {/* Active Filters Display */}
        {(selectedConditions.length > 0 || selectedGpuTypes.length > 0) && (
          <div className={styles.activeFilters}>
            {selectedConditions.map(condition => (
              <div key={`condition-${condition}`} className={styles.filterTag}>
                {condition}
                <span 
                  className={styles.filterTagRemove} 
                  onClick={() => toggleCondition(condition)}
                >
                  ×
                </span>
              </div>
            ))}
            
            {selectedGpuTypes.map(gpuType => (
              <div key={`gpu-${gpuType}`} className={styles.filterTag}>
                {gpuType}
                <span 
                  className={styles.filterTagRemove} 
                  onClick={() => toggleGpuType(gpuType)}
                >
                  ×
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.listingsWrapper}>
        {listings.length === 0 ? (
          <div className={styles.noResults}>
            <h3>No listings match your filters</h3>
            <p>Try adjusting your filter criteria or <button 
              onClick={clearFilters}
              style={{
                backgroundColor: '#3B82F6',
                color: 'white',
                border: 'none',
                borderRadius: '0.375rem',
                padding: '0.5rem 1rem',
                cursor: 'pointer',
                marginLeft: '0.5rem'
              }}
            >
              clear all filters
            </button></p>
          </div>
        ) : (
          <div className={styles.listingsGrid}>
            {listings.map((listing) => (
              <div className={styles.cardWrapper} key={listing.id}>
                <ListingCard 
                  listing={listing}
                  onBidClick={handleBidClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <BidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={selectedListing}
      />
    </div>
  );
};

export default Dashboard;