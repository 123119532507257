// Update HardwareListingCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/ListingCard.module.css';

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : 'Not Provided';
  }
  if (typeof value === 'object' && value !== null) {
    if ('length' in value && 'width' in value && 'height' in value) {
      return `${value.length || 0} × ${value.width || 0} × ${value.height || 0} inches`;
    }
    return 'Not Provided';
  }
  return value || 'Not Provided';
};

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </div>
);

const getQuantityUnit = (listing) => {
  if (!listing || !listing.category) return '';
  
  const category = listing.category.toLowerCase();
  if (category === 'fiber optic networking') return 'meters';
  
  if ((category === 'cooling' || category === 'cooling systems')) {
    return 'plates';
  }
  
  return '';
};

const HardwareListingCard = ({ listing, onBidClick }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/hardware-detail/${listing.id}`);
  };

  const formatCurrency = (value) => {
    return typeof value === 'number' ? value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) : 'Not Available';
  };

  // Calculate total bid if quantity is applicable
  const calculateTotalBid = (listing) => {
    if (!listing.current_highest_bid || !listing.quantity) return listing.current_highest_bid;
    return listing.current_highest_bid * listing.quantity;
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{listing.hardware_type || listing.model}</h3>
        
        <div className={styles.details}>
          <DetailRow label="Category" value={listing.category} />
          <DetailRow label="Condition" value={listing.condition} />
          <DetailRow label="Year" value={listing.year} />
          <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
        </div>

        <div className={styles.priceSection}>
          <div className={styles.price}>
            Ask Price: {formatCurrency(listing.ask_price)}
            {getQuantityUnit(listing) && ` per ${getQuantityUnit(listing)}`}
          </div>
          <div className={`${styles.bids} ${listing.current_highest_bid ? styles.activeBid : ''}`}>
            {listing.current_highest_bid ? (
              <>
                <span>
                  Highest Bid: {formatCurrency(listing.current_highest_bid)}
                  {getQuantityUnit(listing) && ` per ${getQuantityUnit(listing)}`}
                  {listing.quantity && getQuantityUnit(listing) && 
                    ` (${formatCurrency(calculateTotalBid(listing))} total for ${listing.quantity.toLocaleString()} ${getQuantityUnit(listing)})`}
                </span>
              </>
            ) : (
              'No bids yet'
            )}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button 
            onClick={() => onBidClick(listing)}
            className={`${styles.button} ${styles.primaryButton}`}
          >
            Place Bid
          </button>
          <button 
            onClick={handleViewDetails}
            className={styles.button}
          >
            Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default HardwareListingCard;