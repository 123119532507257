import React, { createContext, useContext, useState, useEffect } from 'react';
import hardwareData from '../data/hardware.json';

const HardwareListingsContext = createContext();

export const useHardwareListings = () => useContext(HardwareListingsContext);

export const HardwareListingsProvider = ({ children }) => {
  const [hardwareListings, setHardwareListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    // Generate a random bid that's a certain percentage of the ask price
    const generateRandomBid = (askPrice) => {
      // Random percentage between 40% and 80% of ask price
      const percentage = Math.random() * 0.4 + 0.4;
      return Math.round(askPrice * percentage);
    };

    // Format listings with random bids for all items
    const formattedListings = hardwareData.listings.map(item => {
      // Convert price string to number
      const askPrice = item.price.replace(/[$,k]/g, '').includes('M') 
        ? parseFloat(item.price.replace(/[$,M]/g, '')) * 1000000 
        : parseFloat(item.price.replace(/[$,k]/g, ''));
      
      // Generate random bid amount
      const randomBid = generateRandomBid(askPrice);
      
      // Generate random username for highest bidder
      const randomName = `User${String.fromCharCode(65 + Math.floor(Math.random() * 26))}`;
      
      // Set default quantity for specific categories
      let quantity = undefined;
      if (item.category?.toLowerCase() === 'fiber optic networking') {
        quantity = 10000;
      } else if ((item.category?.toLowerCase() === 'cooling' || item.category?.toLowerCase() === 'cooling systems')) {
        quantity = 128;
      }
      
      return {
        id: item.id,
        hardware_type: item.product,
        manufacturer: item.category.charAt(0).toUpperCase() + item.category.slice(1), 
        category: item.category,
        model: item.product,
        condition: "New", 
        year: "2024", 
        state: "Available",
        country: "USA",
        ask_price: askPrice,
        current_highest_bid: randomBid,
        highest_bidder_username: randomName,
        quantity: quantity,
        specifications: {
          description: item.description,
          details: item.details
        },
        seller_name: "FPX Hardware",
        seller_rating: 4.8
      };
    });

    setHardwareListings(formattedListings);
    setLoading(false);
  }, []);

  const updateListing = (updatedListing) => {
    setHardwareListings(prev => 
      prev.map(listing => 
        listing.id === updatedListing.id ? updatedListing : listing
      )
    );
  };

  const value = {
    hardwareListings,
    loading,
    selectedListing,
    setSelectedListing,
    updateListing,
    allListings: hardwareListings
  };

  return (
    <HardwareListingsContext.Provider value={value}>
      {children}
    </HardwareListingsContext.Provider>
  );
};

export default HardwareListingsContext;