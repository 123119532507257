
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo1 from './assets/amd.jpg';
import logo2 from './assets/nvidia.jpg';
import logo3 from './assets/hpe.jpg';
import logo4 from './assets/dell.jpg';
import logo5 from './assets/supermicro.jpg';
import sampleImage from './assets/sample.jpg';
import './styles/Home.css';
import { useListings } from './ListingsContext';

const GPUHome = () => {
  const navigate = useNavigate();
  const { setListingType } = useListings();
  
  const handleGetStarted = () => {
    setListingType('gpu');
    navigate('/dashboard');
  };

  return (
    <div className="home-container">
      <nav className="custom-navbar">
        <div className="nav-brand">
          <span className="brand-name">FPX HPC Marketplace</span>
        </div>
      </nav>

      <Container>
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="marketplace-title">GPU Server Marketplace</h1>
            <h2 className="subtitle">Your Go To Place For Fully-Transparent<br />HPC Brokerage Services</h2>
            
            <div className="button-container">
              <Button onClick={handleGetStarted} className="get-started-btn">View Listings <span className="arrow">→</span></Button>
            </div>
          </div>
        </div>

        <div className="scroll-content">
          <div className="content-wrapper">
            <h3 className="servers-title">Available Servers</h3>
            
            <div className="partners-section">
              <div className="marquee-container">
                <div className="marquee">
                  <div className="marquee-content">
                    <img src={logo1} alt="AMD" className="partner-logo" />
                    <img src={logo2} alt="NVIDIA" className="partner-logo" />
                    <img src={logo3} alt="HPE" className="partner-logo" />
                    <img src={logo4} alt="Dell" className="partner-logo" />
                    <img src={logo5} alt="Supermicro" className="partner-logo" />
                  </div>
                  <div className="marquee-content">
                    <img src={logo1} alt="AMD" className="partner-logo" />
                    <img src={logo2} alt="NVIDIA" className="partner-logo" />
                    <img src={logo3} alt="HPE" className="partner-logo" />
                    <img src={logo4} alt="Dell" className="partner-logo" />
                    <img src={logo5} alt="Supermicro" className="partner-logo" />
                  </div>
                </div>
              </div>
            </div>

            <div className="tagline-section">
              <p className="tagline gradient-text">
                Experience the future of GPU trading with our Compliant, Transparent, and Cutting-Edge Real-Time Marketplace
              </p>
            </div>

            <div className="preview-section">
              <div className="sample-image">
                <img src={sampleImage} alt="Marketplace Interface" />
              </div>
            </div>
          </div>

          <div className="button-container" style={{ textAlign: 'center', margin: '4rem auto' }}>
            <Button onClick={handleGetStarted} className="gradient-button">Get Started <span className="arrow">→</span></Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GPUHome;