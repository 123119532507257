// ColocationBidModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useColocationListings } from './ColocationListingsContext';
import styles from './styles/BidModal.module.css';

const ColocationBidModal = ({ show, handleClose, listing }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [minBidAmount, setMinBidAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [powerRequired, setPowerRequired] = useState('10');
  const { updateListing } = useColocationListings();

  useEffect(() => {
    if (listing) {
      const minBid = listing.current_highest_bid 
        ? listing.current_highest_bid + 1
        : Math.round(listing.ask_price * 0.75);
      setMinBidAmount(minBid);
      setBidAmount('');
      setIsChecked(false);
      setPowerRequired('10');
    }
    setMessage({ type: '', content: '' });
  }, [listing, show]);

  const calculateTotalBid = () => {
    if (!bidAmount || !powerRequired) return 0;
    return parseFloat(bidAmount) * parseFloat(powerRequired);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });

    if (!listing) {
      setMessage({ type: 'danger', content: 'No colocation listing selected' });
      return;
    }

    if (!isChecked) {
      setMessage({ type: 'danger', content: 'Please confirm the terms and conditions' });
      return;
    }

    const bidAmountFloat = parseFloat(bidAmount);
    
    // Validate bid amount
    if (listing.current_highest_bid && bidAmountFloat <= listing.current_highest_bid) {
      setMessage({ 
        type: 'danger', 
        content: `Bid must be higher than the current highest bid ($${Number(listing.current_highest_bid).toLocaleString()})` 
      });
      return;
    }
    
    if (bidAmountFloat < minBidAmount) {
      setMessage({ 
        type: 'danger', 
        content: `Bid must be at least $${minBidAmount.toLocaleString()}` 
      });
      return;
    }

    // Validate power required
    const powerValue = parseFloat(powerRequired);
    if (!powerValue || powerValue <= 0) {
      setMessage({ 
        type: 'danger', 
        content: `Please enter a valid power requirement in kW` 
      });
      return;
    }

    // Update the listing with the new bid
    const updatedListing = {
      ...listing,
      current_highest_bid: bidAmountFloat,
      highest_bidder_username: 'You', // Mark as user's bid
      power_required: powerValue
    };

    updateListing(updatedListing);
    setMessage({ type: 'success', content: 'Bid placed successfully!' });

    // Close modal after success message
    setTimeout(() => {
      handleClose();
      setMessage({ type: '', content: '' });
      setIsChecked(false);
    }, 2000);
  };

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toLocaleString('en-US');
  };

  if (!listing) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose} className={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Place a Bid on {listing.sitename}</Modal.Title>
        <button className={styles.closeButton} onClick={handleClose}>×</button>
      </Modal.Header>
      
      <Modal.Body className={styles.modalBody}>
        <p className={styles.modalSubtitle}>
          You will secure space in this data center if your bid is the highest when the auction ends
        </p>
        <Form onSubmit={handleSubmit}>
          <div className={styles.infoRow}>
            <div className={styles.infoText}>
              Ask Price: ${formatCurrency(listing?.ask_price)} per kW/month
            </div>
            {listing.current_highest_bid ? (
              <div className={styles.currentBid}>
                Current Highest Bid: ${formatCurrency(listing.current_highest_bid)} per kW/month
              </div>
            ) : (
              <div className={styles.infoText}>
                Minimum Bid: ${formatCurrency(minBidAmount)} per kW/month
              </div>
            )}
          </div>

          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Your Bid Amount (USD per kW/month)
            </Form.Label>
            <Form.Control
              className={styles.formControl}
              type="number"
              step="1"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
              required
              min={minBidAmount}
            />
          </Form.Group>
          
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Power Required (kW)
            </Form.Label>
            <Form.Control
              className={styles.formControl}
              type="number"
              step="1"
              value={powerRequired}
              onChange={(e) => setPowerRequired(e.target.value)}
              required
              min="1"
            />
          </Form.Group>
          
          {bidAmount && powerRequired && (
            <div className={styles.totalBidAmount}>
              <strong>Total Monthly Cost:</strong> ${formatCurrency(calculateTotalBid())}
            </div>
          )}

          <Form.Group className={styles.checkboxGroup}>
            <Form.Check
              type="checkbox"
              id="terms-checkbox"
              className={styles.checkbox}
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              label="I confirm that I understand this bid is binding and subject to the marketplace terms"
            />
          </Form.Group>

          {message.content && (
            <Alert className={`${styles.alert} ${styles[message.type]}`}>
              {message.content}
            </Alert>
          )}

          <Button className={styles.submitButton} type="submit">
            Place Bid
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ColocationBidModal;