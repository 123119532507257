import React, { useState, useEffect, useRef } from 'react';
import HardwareListingCard from './HardwareListingCard';
import HardwareBidModal from './HardwareBidModal';
import { useHardwareListings } from './HardwareListingsContext';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';

const HardwareDashboard = () => {
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  const hardwareListings = useHardwareListings();
  const { setListingType } = useListings();
  
  // Set listing type when component mounts
  useEffect(() => {
    setListingType('hardware');
  }, [setListingType]);

  // Click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Check if the hook returns expected values
  if (!hardwareListings) {
    return <div className={styles.container}>Error loading hardware listings context</div>;
  }
  
  const { hardwareListings: listings = [], loading = false } = hardwareListings;

  // Create categories based on the actual data structure
  const categories = [
    { id: 'all', name: 'All Categories' },
    { id: 'storage', name: 'Storage' },
    { id: 'fiber optic networking', name: 'Fiber optic networking' },
    { id: 'network interface cards', name: 'Network interface cards' },
    { id: 'generators', name: 'Generators' },
    { id: 'uninterruptible power supply', name: 'Uninterruptible power supply' },
    { id: 'cooling', name: 'Cooling' },
    { id: 'cooling systems', name: 'Cooling systems' },
    { id: 'power distribution units', name: 'Power distribution units' },
    { id: 'high speed interconnects', name: 'High speed interconnects' }
  ];

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
    setDropdownOpen(false);
  };

  // Get the currently selected category name
  const selectedCategoryName = categories.find(cat => cat.id === selectedCategory)?.name || 'All Categories';

  // Filter listings by selected category using category field
  const filteredListings = selectedCategory === 'all' 
  ? listings 
  : listings.filter(listing => {
      // Check if listing and listing.category exist before calling toLowerCase()
      return listing && listing.category && 
        listing.category.toLowerCase() === selectedCategory.toLowerCase();
    });

  if (loading) {
    return <div className={styles.container}>Loading hardware listings...</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Datacenter Equipments Listings</h1>
      
      <div className={styles.filterContainer}>
        <div className={styles.dropdownContainer} ref={dropdownRef}>
          <button 
            className={styles.dropdownToggle} 
            onClick={toggleDropdown}
          >
            <span>{selectedCategoryName}</span>
            <svg className={`${styles.chevron} ${dropdownOpen ? styles.open : ''}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          
          {dropdownOpen && (
            <div className={styles.dropdownMenu}>
              {categories.map(category => (
                <div 
                  key={category.id} 
                  className={`${styles.dropdownItem} ${selectedCategory === category.id ? styles.active : ''}`}
                  onClick={() => handleCategorySelect(category.id)}
                >
                  {category.id === selectedCategory && (
                    <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  )}
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.listingsWrapper}>
        {filteredListings.length === 0 ? (
          <div className="text-center mt-4">
            <h3>No Datacenter Equipment listings available for this category</h3>
          </div>
        ) : (
          <div className={styles.listingsGrid}>
            {filteredListings.map((listing) => (
              <div className={styles.cardWrapper} key={listing.id}>
                <HardwareListingCard 
                  listing={listing}
                  onBidClick={handleBidClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <HardwareBidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={selectedListing}
      />
    </div>
  );
};

export default HardwareDashboard;